import React, { useEffect, useState } from 'react';
import { Addons, GET_WA_PRODUCT_CATALOGUES } from "../../../auth/core/_requests"
import Loader from '../../../../pages/Loader';
import { SMSdata } from '../../../../pages/Whatsapp_Interface/Interface';
import { KTIcon, toAbsoluteUrl } from '../../../../../_metronic/helpers';

interface Product {
    name: string;
    price: string;
    visibility: string;
    currency: string;
    condition: string;
    availability: string;
    description: string;
    image_url: string;
    inventory: number;
    url: string;
    id: string;
}

interface ApiResponse {
    data: Product[];
    paging: {
        cursors: {
            before: string;
            after: string;
        };
        next: string;
        previous: string;
    };
}

const fields_array = ["category", "name", "price", "origin_country", "visibility", "product_type", "currency", "condition", "availability", "additional_image_urls", "description", "image_url", "inventory", "url", "retailer_id", "sale_price", "short_description"];
const fields = fields_array.join(',');
const limit = 10;

const Catalogue = () => {
    const [apiData, setApiData] = useState<ApiResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [before, setBefore] = useState<string | null>(null);
    const [after, setAfter] = useState<string | null>(null);
    const [prevButtonDisabled, setPrevButtonDisabled] = useState<boolean>(true);
    const [addons, setAddons] = useState<SMSdata[]>([]);
    const [tokenAccess, setTokenAccess] = useState('')


    const GET_CATALOGUES = async (access_token: any, fields: any, limit: number, before: string, after: any) => {
        try {
            setLoading(true);
            const response = await GET_WA_PRODUCT_CATALOGUES(access_token, fields, limit, before, after);
            setApiData(response.data);
            setBefore(response.data.paging.cursors.before);
            setAfter(response.data.paging.cursors.after);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    let tokenData: any
    const AccessToken = async (addon: any) => {
        try {
            const response = await Addons(addon);
            setAddons(response.data.addon_details);
            if (response.data.addon_details) {
                const initialData = response.data.addon_details
                const addonMetaData = JSON.parse(initialData[0].addon_meta_data);
                tokenData = addonMetaData.access_token
                setTokenAccess(tokenData)
                GET_CATALOGUES(tokenData, fields, limit, '', '');
            }
        } catch (error) {
            console.error('Error fetching addons:', error);
        }
    };

    useEffect(() => {
        AccessToken("whatsapp");
    }, []);



    const handleNextPage = () => {
        if (after) {
            GET_CATALOGUES(tokenAccess, fields, limit, '', after);
            setPrevButtonDisabled(false);
        }
    };

    const handlePrevPage = () => {
        if (before) {
            GET_CATALOGUES(tokenAccess, fields, limit, before, '');
        }
    };

    if (!apiData) {
        return (
            <Loader />
        );
    }

    return (
        <>
            <div className='card'>
                <div className='card-header'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Product Catalogues</span>
                    </h3>
                    <div className='card-toolbar'>
                        <div>
                            {/* <button className='btn btn-primary' onClick={handleTemplateCreate}>
                                <KTIcon iconName='plus' className='fs-2' />
                                Create Template
                            </button> */}
                            {/* <a href='https://docs.google.com/spreadsheets/d/1s2sLpWkxDjAN3nUBcH7xgl_cLJZQ31azx5JKRUd9Oqg/edit#gid=201443646' target='_new' className='btn btn-primary btn-sm' >

                                Manage Catalogue
                                <KTIcon iconName='arrow-up-right' className='fs-2' />
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className='card mt-5 p-5'>
                {loading ? (
                    <Loader />
                ) : (apiData?.data.length > 0 ? (
                    <>
                        <table className="table gs-7 gy-7 gx-7">
                            <thead>
                                <tr className="fw-bolder fs-6 text-gray-800">
                                    <th>Name</th>
                                    <th >Description</th>
                                    <th >Price</th>
                                    <th >Availibility</th>
                                    <th >Stock</th>
                                    <th >Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiData?.data.map((product, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <div className='symbol symbol-50px me-5'>
                                                    {product?.image_url && (
                                                        <img src={`${product?.image_url}`} alt={product.name}
                                                            style={{ borderRadius: '50%', overflow: 'hidden', width: '50px', height: '50px', objectFit: 'cover' }} />
                                                    )}
                                                </div>
                                                <div className='d-flex justify-content-start flex-column'>
                                                    <a className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                                        {product.name}
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{product.description}</td>
                                        <td>{product.price}</td>
                                        <td>{product.availability}</td>
                                        <td>{product.inventory}</td>
                                        <td>
                                            {product.visibility === "hidden" ? (
                                                <span className="badge bg-dark text-white">Un-published</span>
                                            ) : product.visibility === "published" ? (
                                                <span className="badge bg-success text-white">Published</span>
                                            ) : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div>
                            <div className='float-end cursor-pointer'>
                                <button className='btn' disabled={prevButtonDisabled} onClick={handlePrevPage}>
                                    <i className="fa-solid fa-circle-chevron-left text-primary" style={{ fontSize: "40px" }}></i>
                                </button>
                                <button className='btn' disabled={apiData?.data.length === 0 || loading} onClick={handleNextPage}>
                                    <i className="fa-solid fa-circle-chevron-right ps-5 pe-5 text-primary" style={{ fontSize: "40px" }}></i>
                                </button>
                            </div>
                        </div>
                    </>
                ) :
                    <div className="d-flex justify-content-center p-10">
                        <div>
                            <img src={toAbsoluteUrl('/media/stock/coming_soon.png')} className='w-350px' alt='coming soon' />
                            <h3 className="text-center">No Catalogues Found</h3>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default Catalogue;