import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import Dashboard from '../pages/dashboard/Dashboard'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import WhatsappPage from '../modules/whatsapp/WhatsappPage'
import EmailConfigure from '../modules/whatsapp/pages/Integration/pages/EmailConfigure'
import SMSConfigure from '../modules/whatsapp/pages/Integration/pages/SMSConfigure'
import Razorpay from '../modules/whatsapp/pages/Integration/pages/Razorpay'
import WhatsappConfigure from '../modules/whatsapp/pages/Settings/pages/WhatsappConfigure'
import Catalogue from '../modules/whatsapp/pages/Settings/pages/CatalogueConfigure'
import WhatsappStore from '../modules/whatsapp/pages/Settings/pages/WhatsappStore' 
//import { useAuth } from '../modules/auth'
import Orders from '../pages/orders/Orders'
import OrdersReport from '../modules/report/OrdersReport'
import Contact from '../pages/contact/Contact'

const PrivateRoutes = () => {
  //const { currentUser } = useAuth()
 // const [userRole] = useState(currentUser?.user_role || '')
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path="/contacts" element={<Contact />} />
        <Route path="/orders" element={<Orders />} /> 
        <Route path="/integration/email" element={<EmailConfigure />} />
        <Route path="/integration/sms" element={<SMSConfigure />} />
        <Route path="/integration/razorpay" element={<Razorpay />} />
        <Route path="/settings/whatsapp" element={<WhatsappConfigure />} />
        <Route path="/settings/catalogue" element={<Catalogue />} />
        <Route path="/settings/whatsapp-store" element={<WhatsappStore />} />
        <Route path="/report" element={<OrdersReport />} /> 
        <Route path="/order-status/:order_status" element={<Orders />} />
        <Route path="/total-orders" element={<Orders />} />  
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='whatsapp/*'
          element={
            <SuspensedView>
              <WhatsappPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-success')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
